import React, { useContext } from "react";

import { graphql } from "gatsby";

import { DiscordButton } from "../../components/buttons/discord";
import { LanguageRegionContext } from "../../context-providers/language-region-context";
import { ContentLayout } from "../../layouts/content-layout";
import { DefaultLayout } from "../../layouts/default-layout";

// CSS
import "./contact-us.scss";

const ContactUsTemplate = ({ data, pageContext }) => {
    const { languageRegion } = useContext(LanguageRegionContext);

    const pageDetails = {
        title: pageContext.title
    };

    const companyAddress = (data && data.site && data.site.siteMetadata && data.site.siteMetadata.companyAddress) || "";

    return (
        <DefaultLayout pageDetails={pageDetails}>
            <ContentLayout title={pageContext.title}>
                <div className="p-contact-us">

                    <h2>Discord</h2>
                    <p>The fastest and easiest way to contact the team is through our community discord.</p>
                    <div className="g-flex__container">
                        <DiscordButton languageRegion={languageRegion} />
                    </div>
                    <h2 className="u-margin-bottom--1">Contact Address</h2>
                    {companyAddress && companyAddress.map((addressLine) => {
                        return <><span>{addressLine}</span><br /></>;
                    })}


                </div>
            </ContentLayout>
        </DefaultLayout>
    );
};

export const query = graphql`
    query ContactUsQuery {
        site {
            siteMetadata {
                layout
                companyAddress
            }
        }
    }
`;

export default ContactUsTemplate;
